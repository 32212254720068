import axios from "axios";
import { store } from "../redux/store";
import { updateCustomerInfoState } from "../redux/customerInfoSlice";
import {
  updatePinNotice,
  updatePinAttempts,
  updatePinGlobal,
} from "../redux/globalSlice";
import { updateWifiData } from "../redux/wifiSlice";

export const handleInvalidAttempt = (): void => {
  const { pinAttempts } = store.getState().global;

  // first attempt
  if (pinAttempts === 0) {
    store.dispatch(
      updatePinNotice({
        color: "red-bold",
        text: "INCORRECT - 2 attempts remaining",
      })
    );
    store.dispatch(updatePinAttempts(1));
  }
  // second attempt
  if (pinAttempts === 1) {
    store.dispatch(
      updatePinNotice({
        color: "red-bold",
        text: "INCORRECT - 1 attempt remaining",
      })
    );
    store.dispatch(updatePinAttempts(2));
  }
  // third attempt
  if (pinAttempts === 2) {
    store.dispatch(
      updatePinNotice({
        color: "red-bold",
        text:
          "Failed to ID - USE CAUTION - Please verify your customer via THREE security questions",
      })
    );
    store.dispatch(updatePinAttempts(3));
  }
};

export const getPINVerify = (pin: string): void => {
  const { isMSISDNSearch, searchAccount } = store.getState().search;
  const { login, token } = store.getState().login;
  const customerInfo = { ...store.getState().customer.customerInfo };
  store.dispatch(updatePinGlobal(pin));

  let body;
  if (isMSISDNSearch) {
    body = {
      pin,
      msisdn: searchAccount,
    };
  } else {
    body = {
      pin,
      "account-number": searchAccount,
    };
  }
  axios
    .post(`${login.oneAssistApi}/siebel/validate-pin`, JSON.stringify(body), {
      headers: { Authorization: token.idToken },
    })
    .then((response) => {
      if (response.data.result === "valid") {
        store.dispatch(updateCustomerInfoState(customerInfo));
        store.dispatch(
          updatePinNotice({
            color: "green",
            text: "Correct - Authenticated",
          })
        );
        store.dispatch(updatePinAttempts(0));
      }
      if (response.data.result === "invalid") {
        handleInvalidAttempt();
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getWifiDetail = (wifiKey: string): void => {
  const { login, token } = store.getState().login;
  axios
    .get(
      `${login.adminApi}/platform/messages-public/OA_ConditionalMessages/key/${wifiKey}`,
      {
        headers: { Authorization: token.idToken },
      }
    )
    .then((response) => {
      const dropdowns = Object.keys(response.data)
        .filter((key) => /^dropdown_\d+$/.test(key))
        .map((key) => response.data[key]);

      const combinedDropdown = dropdowns.filter(Boolean).join(" ");
      const wifiData = {
        dropdown: combinedDropdown || "",
        dropdown_title: response.data.dropdown_title || "",
        headline: response.data.headline || "",
        message_body: response.data.message_body_ta || "",
        message_header: response.data.message_header || "",
        shown_dropdown: response.data.shown_dropdown || "",
      };
      store.dispatch(updateWifiData(wifiData));
    })
    .catch((error) => {
      console.error(error);
    });
};

interface DropdownOption {
  value: string;
  label: string;
  link: string;
}

export const extractDropdownOptions = (
  inputString: string
): DropdownOption[] => {
  if (!inputString) {
    return [];
  }

  const regex = /\[DISPLAY\](.*?) \[URL\] (.*?)(?=\[DISPLAY\]|$)/g;
  const matches = Array.from(inputString.matchAll(regex));

  const dropdownOptions: DropdownOption[] = [];

  matches.forEach((match) => {
    const option: DropdownOption = {
      value: match[1].trim(),
      label: match[1].trim(),
      link: match[2].trim(),
    };

    dropdownOptions.push(option);
  });

  return dropdownOptions;
};
