import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import FAQDropdown from "./FAQDropdown";
import {
  getWifiDetail,
  extractDropdownOptions,
} from "../../helpers/AssistHelper";
import { wifiConfig } from "../../constants/defaultConfig";
import { updateWifiKey } from "../../redux/wifiSlice";

const WifiTool: React.FC = () => {
  const {
    modemAuthNotice,
    lanTestNotice,
    ONTStatus,
    rgwBandWidth,
    ONTOverTime,
  } = useAppSelector((state) => state.toolset);
  const { decoAccountFlag } = useAppSelector((state) => state.service.service);
  const { wifiData, wifiKey } = useAppSelector((state) => state.wifi);
  const [showWifiTool, setShowWifiTool] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    /* 212 condition
    // modemAuth true
    // Check SV/CV LAN | Handover Match
    // ONT switch on
    // ONT rgw status = In service
    // ONT status overtive = no errors
    // DECO_ACCOUNT_FLAG = "1"
    */
    if (
      modemAuthNotice === "Online" &&
      lanTestNotice === "Match" &&
      ONTStatus.ONTStatusNotice === "ONT Switched On" &&
      rgwBandWidth.rgwBandWidthNotice === "In Service" &&
      ONTOverTime.notice === "No errors detected" &&
      decoAccountFlag === "1"
    ) {
      setShowWifiTool(true);
      dispatch(updateWifiKey("212"));
      getWifiDetail("212");
    }

    /* 213 condition
    // modemAuth true
    // Check SV/CV LAN | Handover Match
    // ONT switch on
    // ONT rgw status = In service
    // ONT status overtive = no errors
    // DECO_ACCOUNT_FLAG = null
    */
    if (
      modemAuthNotice === "Online" &&
      lanTestNotice === "Match" &&
      ONTStatus.ONTStatusNotice === "ONT Switched On" &&
      rgwBandWidth.rgwBandWidthNotice === "In Service" &&
      ONTOverTime.notice === "No errors detected" &&
      decoAccountFlag === null
    ) {
      setShowWifiTool(true);
      dispatch(updateWifiKey("213"));
      getWifiDetail("213");
    }
    /* 214 condition
    // modemAuth true
    // Check SV/CV LAN | Handover Match
    // ONT switch on
    // ONT status overtive = Data not available for this provider
    // DECO_ACCOUNT_FLAG = "null"
    */
    if (
      modemAuthNotice === "Online" &&
      lanTestNotice === "Match" &&
      ONTStatus.ONTStatusNotice === "ONT Switched On" &&
      ONTOverTime.notice === "Data not available for this provider" &&
      decoAccountFlag === null
    ) {
      setShowWifiTool(true);
      dispatch(updateWifiKey("214"));
      getWifiDetail("214");
    }
    /* 215 condition
    // modemAuth true
    // Check SV/CV LAN | Handover Match
    // ONT switch on
    // ONT status overtive = Data not available for this provider
    // DECO_ACCOUNT_FLAG = "1"
    */
    if (
      modemAuthNotice === "Online" &&
      lanTestNotice === "Match" &&
      ONTStatus.ONTStatusNotice === "ONT Switched On" &&
      ONTOverTime.notice === "Data not available for this provider" &&
      decoAccountFlag === "1"
    ) {
      setShowWifiTool(true);
      dispatch(updateWifiKey("215"));
      getWifiDetail("215");
    }
  }, [
    modemAuthNotice,
    lanTestNotice,
    ONTStatus.ONTStatusNotice,
    rgwBandWidth.rgwBandWidthNotice,
    ONTOverTime.notice,
    decoAccountFlag,
    dispatch,
  ]);

  if (!showWifiTool || !wifiKey) {
    return null; // Return null if showWifiTool is false
  }

  return (
    <div className="wifi-tool">
      <div>
        <p className="wifi-key header">#{wifiKey}</p>
        <p className="wifi-headline header">
          {wifiData.headline || (wifiConfig[wifiKey]?.headline ?? "")}
        </p>
        <p className="wifi-message-header header">
          {wifiData.message_header ||
            (wifiConfig[wifiKey]?.message_header ?? "")}
        </p>
      </div>
      <div
        className="wifi-message-body"
        dangerouslySetInnerHTML={{
          __html: (
            wifiData.message_body ||
            wifiConfig[wifiKey]?.message_body ||
            ""
          ).replace(/\n/g, "<br>"),
        }}
      />

      {wifiData.shown_dropdown === "yes" ||
      wifiConfig[wifiKey]?.shown_dropdown === "yes" ? (
        <div className="faq">
          <FAQDropdown
            options={
              extractDropdownOptions(wifiData.dropdown || "") ||
              wifiConfig[wifiKey].dropdown
            }
            title={
              wifiData.dropdown_title ||
              (wifiConfig[wifiKey]?.dropdown_title ?? "")
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default WifiTool;
