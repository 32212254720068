import React from "react";
import CustomerInfo from "./CustomerInfo";
import ProgressBar from "./ProgressBar";
import StateWrapper from "./StateWrapper";
import WifiTool from "./WifiTool";
import { useAppSelector } from "../../redux/hooks";
import "./top.css";

const TopWrapper: React.FC = () => {
  const { userRole } = useAppSelector((state) => state.login);
  return (
    <div className="topWrapper">
      <div className="logo_data">
        <div className="logo">
          <p className="logo_img" />
          <p className="logo_title">OneAssist</p>
        </div>
        <div className="dataWrapper">
          <CustomerInfo />
        </div>
      </div>
      <ProgressBar />
      {userRole === "NOT FOUND" && (
        <div className="noAccess">
          You have no access, please contact your team leader.
        </div>
      )}
      <StateWrapper />
      <WifiTool />
    </div>
  );
};

export default TopWrapper;
