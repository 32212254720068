/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import _ from "lodash";
import axios from "axios";
import { store } from "../redux/store";
import {
  updateCustomerInfoState,
  updateAccountInfoState,
  getUpdateTime,
  CustomerInfoType,
  clearCustomerData,
} from "../redux/customerInfoSlice";
import {
  updateServiceState,
  updateUsername,
  updateASID,
  updateCartridge,
  updateStaticIP,
  updateLan,
  LANType,
  getLineTestLan,
  clearServiceData,
} from "../redux/serviceSlice";
import {
  showProgressBar,
  updateSupport,
  disableAddressSearch,
  updateSearchNotice,
  clearGlobalData,
  showAddressError,
  updateCartridgeGroup,
  updateVulnerable,
  updateVoIP,
  updateSiebelStatus,
} from "../redux/globalSlice";
import {
  getModemAuth,
  getLoginHistory,
  getInternetUsage,
  getOutages,
} from "./ToolsetHelper";
import { checkLineTest } from "./LineTestHelper";
import { otherLFCLineTest } from "./OtherLFCHelper";
import { clearToolsetData, updateUnplannedOutage } from "../redux/toolsetSlice";
import {
  updateSearchType,
  updateResultStatus,
  updateTableGroup,
  updateSearchAccount,
  clearSearchData,
} from "../redux/searchSlice";
import { clearWifiData } from "../redux/wifiSlice";
import { TPIIApis } from "../constants/defaultConfig";

type ServiceValues = {
  samId: string;
  LFC: string;
};

const clearInfo = (): void => {
  // clear first
  store.dispatch(updateCustomerInfoState({} as CustomerInfoType));
  store.dispatch(updateASID(""));
  store.dispatch(updateLan({} as LANType));
  store.dispatch(getLineTestLan({} as LANType));
};

export const isMSISDN = (account: string): boolean => {
  const phoneNumberRegex = /^(0064|\+?64|0|00640|640|\+640)([1-9]\d{7,9}|(3|4|6|7|9)[1-9]\d{5})$/;
  if (phoneNumberRegex.test(account)) {
    store.dispatch(updateSearchType(true));
    return true;
  }
  store.dispatch(updateSearchType(false));
  return false;
};

export const formatMSISDN = (number: string): string => {
  const phoneNumberRegex = /^(0064|\+?64|0|00640|640|\+640)([1-9]\d{7,9}|(3|4|6|7|9)[1-9]\d{5})$/;
  const match = number.match(phoneNumberRegex);
  if (match) {
    const digits = match[2];
    return `64${digits}`;
  }
  return number;
};

// eslint-disable-next-line
export const filterSearchData = (data: any, isCLRSearch: boolean): any => {
  const uniqueACCTNums = new Set<string>();
  const filterData = data.filter((obj: any) => {
    // hide CLR account, but not hide if the search is a CLR***
    if (obj.SOURCE_SYSTEM === "CLR" && !isCLRSearch) {
      return false;
    }
    // hide TPII account
    if (obj.SOURCE_SYSTEM === "TPII" && obj.FIS_FLAG === "Y") {
      return false;
    }
    // Hide SBL if Mobile only
    if (
      obj.SOURCE_SYSTEM === "SBL" &&
      obj.HWB_FLAG === "N" &&
      obj.HPW_FLAG === "N" &&
      obj.ADSL_FLAG === "N" &&
      obj.VDSL_FLAG === "N" &&
      obj.FIBRE_FLAG === "N" &&
      obj.HFC_FLAG === "N"
    ) {
      return false;
    }
    // Hide if HWB = Y AND FIS = N AND FIBRE = N
    if (
      obj.HWB_FLAG === "Y" &&
      obj.FIS_FLAG === "N" &&
      obj.FIBRE_FLAG === "N"
    ) {
      return false;
    }
    // Hide if VOIP_FLAG = Y
    if (obj.VOIP_FLAG === "Y") {
      store.dispatch(updateVoIP(true));
      return false;
    }
    // Hide if HPW_FLAG = Y
    if (obj.HPW_FLAG === "Y") {
      return false;
    }

    if (uniqueACCTNums.has(obj.ACCT_NUM)) {
      return false; // Skip this item if the ACCT_NUM is already encountered
    }
    uniqueACCTNums.add(obj.ACCT_NUM);

    return true;
  });
  return filterData;
};

// eslint-disable-next-line
export const createTableGroup = (filterData: any): void => {
  const tableGroup = filterData.map((item: any) => ({
    CRM: item.SOURCE_SYSTEM,
    status: item.SERVICE_STATUS_NAME || "",
    firstName: item.CONTACT_FIRST_NAME,
    lastName: item.CONTACT_LAST_NAME,
    DOB: item.DOB,
    accountType: item.PROD_NAME || "Plan Unknown",
    connectType: getConnectType(item),
    fixedType: item.FIS_FLAG === "Y" ? "FIXED in SBL" : "",
    vulnerable:
      item.SERVICE_VULNERABLE_CUSTOMER_FLAG === "Y" ? "VULERABLE CUSTOMER" : "",
    searchKey: item.ACCT_NUM,
    accountData: item,
  }));
  store.dispatch(updateTableGroup(tableGroup));
};

export const whitelistSearch = (
  account: string,
  isFromAccountSearch: boolean
): void => {
  const { oneAssistApi } = store.getState().login.login;
  const { token } = store.getState().login;
  if (isFromAccountSearch) {
    store.dispatch(clearCustomerData());
    store.dispatch(clearServiceData());
    store.dispatch(clearGlobalData());
    store.dispatch(clearToolsetData());
    store.dispatch(clearSearchData());
    store.dispatch(clearWifiData());
    // Disable Address Search
    store.dispatch(disableAddressSearch(true));
    store.dispatch(updateSearchAccount(account));
  }
  store.dispatch(
    showProgressBar({
      isShown: true,
      pbMessage: "Customer details loading...",
    })
  );

  let whitelistSearchPath = "";
  // is CLR account ?
  const isCLRAccount = account.toLowerCase().startsWith("clr");
  if (isCLRAccount) {
    whitelistSearchPath = `${oneAssistApi}/whitelist-query/account-number/${account.slice(
      3
    )}`;
  } else {
    // is MSISDN ?
    whitelistSearchPath = isMSISDN(account)
      ? `${oneAssistApi}/whitelist-query/msisdn/${formatMSISDN(account)}`
      : `${oneAssistApi}/whitelist-query/account-number/${account}`;
  }

  let currentRetry = 0;

  const performWhitelistSearch = () => {
    axios
      .get(whitelistSearchPath, {
        headers: {
          Authorization: token.idToken,
        },
      })
      .then((response) => {
        if (response.data.length >= 1) {
          const filterData = filterSearchData(response.data, isCLRAccount);
          if (filterData.length < 1) {
            store.dispatch(updateSupport(false));
            store.dispatch(updateResultStatus(false));
          } else if (filterData.length === 1) {
            displayCustomerInfo(filterData[0], isFromAccountSearch);
            displayServiceInfo(filterData[0]);
            displayUpdateTime();
            store.dispatch(updateResultStatus(false));
            store.dispatch(updateSearchNotice(""));
          } else {
            // multi results
            store.dispatch(updateResultStatus(true));
            createTableGroup(filterData);
            displayCustomerInfo(filterData[0], isFromAccountSearch);
            displayServiceInfo(filterData[0]);
            displayUpdateTime();
            store.dispatch(updateSearchNotice(""));
          }
        } else {
          store.dispatch(updateSearchNotice("Unable to find account"));
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 404
        ) {
          store.dispatch(updateSearchNotice("Unable to find account"));
        }

        if (
          error.response &&
          error.response.status &&
          error.response.status === 504
        ) {
          currentRetry += 1;
          if (currentRetry <= 2) {
            performWhitelistSearch();
          } else {
            store.dispatch(updateSearchNotice("Network Error"));
          }
        }
        store.dispatch(
          showProgressBar({
            isShown: false,
            pbMessage: "",
          })
        );
      });
  };
  performWhitelistSearch();
};

const getCustomerID = (samId: string): void => {
  clearInfo();
  const { login, token } = store.getState().login;

  if (samId) {
    const apiPath = login.useTPIIProxy
      ? `${login.oneAssistApi}/tpii/customer?search_type=sam_id&info_flag=1&search_value=${samId}`
      : `${TPIIApis.getCustomerNumber}${samId}`;

    const config = login.useTPIIProxy
      ? { headers: { Authorization: token.idToken } }
      : {};
    axios
      .get(apiPath, config)
      .then((response) => {
        if (response.data.status === "Ok") {
          store.dispatch(showAddressError(false));
          const activeCustomer = response.data.data.customer.filter(
            (data: any) => {
              //eslint-disable-line
              return data.status === "Active";
            }
          );
          if (activeCustomer.length > 0) {
            const customerID =
              activeCustomer[0].converged.brm_billing_account_no;
            if (customerID) {
              whitelistSearch(customerID, false);
            } else {
              store.dispatch(showAddressError(true));
            }
          } else {
            store.dispatch(showAddressError(true));
          }
        }
      })
      .catch(() => {
        store.dispatch(showAddressError(true));
      });
  }
};

const getValueFromAccessMethods = (json: any, type: string): string => {
  if (json.accessMethods.fibre.length > 0) {
    // check fibre first
    const accessMethod = json.accessMethods.fibre[0];
    if (type === "LFC") {
      if (
        accessMethod.serviceabilityType === "serviceable" &&
        accessMethod.accessType === "ufb"
      ) {
        // ATM: Support Chorus , Enable, TFF and Northpower
        if (
          accessMethod.provider.name === "LFC Chorus" ||
          accessMethod.provider.name === "LFC Enable" ||
          accessMethod.provider.name === "LFC UFF" ||
          accessMethod.provider.name.includes("Northpower")
        ) {
          return accessMethod.provider.name;
        }
        store.dispatch(updateSupport(false));
        store.dispatch(
          showProgressBar({
            isShown: false,
            pbMessage: "",
          })
        );
        return accessMethod.provider.name || "Unknown";
      }
    }
  } else {
    // then go to dsl
    const accessMethod = json.accessMethods.dsl[0];
    if (type === "LFC") {
      store.dispatch(updateSupport(false));
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      return accessMethod.provider.name;
    }
  }

  return "Unknown";
};

const renameLFC = (LFC: string): string => {
  const { connectType } = store.getState().service.service;
  if (LFC === "default" && connectType === "HFC (Hybrid Fibre Coaxial)") {
    return "One NZ";
  }
  return LFC;
};

export const getServiceValue = (
  data: any, // eslint-disable-line
  isFromSiebel: boolean
): ServiceValues => {
  const serviceValue = {
    samId: "",
    LFC: "",
  };
  // samId
  serviceValue.samId = data.address.samId;
  // Go TPII Logic
  if (!isFromSiebel) {
    getCustomerID(serviceValue.samId);
  }
  // LFC
  serviceValue.LFC = renameLFC(getValueFromAccessMethods(data, "LFC"));
  return serviceValue;
};

export const getServiceFeasibility = (
  slamId: string,
  isFromSiebel: boolean
): void => {
  store.dispatch(
    showProgressBar({
      isShown: true,
      pbMessage: "Service details loading...",
    })
  );
  if (!isFromSiebel) {
    // clear first
    store.dispatch(clearCustomerData());
    store.dispatch(clearServiceData());
    store.dispatch(clearGlobalData());
    store.dispatch(clearToolsetData());
  }
  let serviceValues = store.getState().service.service;
  const { login, token } = store.getState().login;
  axios
    .get(`${login.oneAssistApi}/address-checker/serviceability/${slamId}`, {
      headers: {
        Authorization: token.idToken,
      },
    })
    .then((response) => {
      if (response.data) {
        const moreServiceValues = getServiceValue(response.data, isFromSiebel);
        serviceValues = {
          ...serviceValues,
          ...moreServiceValues,
          SLAM: slamId,
        };
        store.dispatch(updateServiceState(serviceValues));
        store.dispatch(showAddressError(false));
        store.dispatch(
          showProgressBar({
            isShown: false,
            pbMessage: "",
          })
        );
      }
    })
    .catch(() => {
      store.dispatch(showAddressError(true));
    });
};

// eslint-disable-next-line
export const displayMobileNumber = (response: any): string => {
  if (response.CONTACT_NUMBER) {
    return response.CONTACT_NUMBER;
  }
  if (response.CONTACT_PH_NUM_LL_1) {
    return response.CONTACT_PH_NUM_LL_1;
  }
  if (response.CONTACT_PH_NUM_LL_2) {
    return response.CONTACT_PH_NUM_LL_2;
  }
  if (response.CONTACT_PH_NUM_MOB_1) {
    return response.CONTACT_PH_NUM_MOB_1;
  }
  if (response.CONTACT_PH_NUM_MOB_2) {
    return response.CONTACT_PH_NUM_MOB_2;
  }
  if (response.CUST_PH_NUM) {
    return response.CUST_PH_NUM;
  }
  if (response.MSISDN) {
    return response.MSISDN;
  }
  return "Unknown - Check CRM";
};

export const renameYN = (yn: string): string => {
  if (yn === "Y") return "Yes";
  if (yn === "N") return "No";
  return "Unknown";
};

const getASIDLanStaticIP = (response: any): void => {
  const asid = _.get(response, [
    "data",
    "data",
    "service_details",
    "line_service_address",
    "asid",
  ]);
  store.dispatch(updateASID(asid));
  const CV = _.get(response, [
    "data",
    "data",
    "service_details",
    "line_customervlan",
  ]);
  const SV = _.get(response, [
    "data",
    "data",
    "service_details",
    "line_servicevlan",
  ]);
  const handoverPoint = _.get(response, [
    "data",
    "data",
    "service_details",
    "line_handoverpoint",
  ]);
  store.dispatch(updateLan({ CV, SV, handover: handoverPoint }));

  setTimeout(() => {
    const { isSupport } = store.getState().global;
    const { LFC } = store.getState().service.service;
    // auto run toolset of lan and ONT
    // only run when LFC is "LFC Chorus" (supported)
    if (isSupport) {
      const { cartridge, username } = store.getState().service;
      if (LFC === "LFC Chorus") {
        checkLineTest(true, asid);
        getOutages(asid, "Chorus");
      }
      if (LFC === "LFC UFF") {
        getOutages(asid, "TFF");
        otherLFCLineTest(true, asid);
      }
      if (LFC === "LFC Enable" || LFC.includes("Northpower")) {
        otherLFCLineTest(true, asid);
        store.dispatch(
          updateUnplannedOutage({
            outageLight: "grey",
            outageTitle: "Data not available for this provider",
            outageNotice: "",
          })
        );
      }
      getLoginHistory();
      getInternetUsage(cartridge, username);
    }
  }, 3500);

  const staticIP = response.data.data.static_ip;
  if (staticIP === "N" || staticIP === "" || staticIP === null) {
    store.dispatch(updateStaticIP("No"));
  } else if (staticIP === "Y") {
    store.dispatch(updateStaticIP("Yes"));
  } else {
    store.dispatch(updateStaticIP(staticIP));
  }

  store.dispatch(
    showProgressBar({
      isShown: false,
      pbMessage: "",
    })
  );
};

export const getSlamid = (
  cartridgeNumber: string,
  userName: string,
  isFromAccountSearch: boolean
): void => {
  // siebel flow step2: get slamId
  const { login, token } = store.getState().login;
  const apiPath = login.useTPIIProxy
    ? `${login.oneAssistApi}/tpii/customer/${cartridgeNumber}/service/internet/${userName}`
    : `${TPIIApis.getTPIICustomerData}${cartridgeNumber}/service/internet/${userName}`;

  const config = login.useTPIIProxy
    ? { headers: { Authorization: token.idToken } }
    : {};
  axios
    .get(apiPath, config)
    .then((response) => {
      if (response.data.status === "Ok") {
        const slamId = _.get(response, [
          "data",
          "data",
          "service_details",
          "line_service_address",
          "slamid",
        ]);
        // Go to Address checker flow to get more service values
        if (isFromAccountSearch) {
          getServiceFeasibility(slamId, true);
        }
        // Get asid and lan values
        getASIDLanStaticIP(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getTPIIUserName = (
  cartridgeNumber: string,
  isFromAccountSearch: boolean
): void => {
  const { login, token } = store.getState().login;
  const accountInfo = { ...store.getState().customer.accountInfo };
  // siebel flow step2: get username
  if (cartridgeNumber) {
    const apiPath = login.useTPIIProxy
      ? `${login.oneAssistApi}/tpii/customer/${cartridgeNumber}/service`
      : `${TPIIApis.getTPIICustomerData}${cartridgeNumber}/service`;

    const config = login.useTPIIProxy
      ? { headers: { Authorization: token.idToken } }
      : {};
    axios
      .get(apiPath, config)
      .then((response) => {
        if (response.data.status === "Ok") {
          // restriction status
          let restrictionStatus = "No";
          if (
            response.data.data.internet[0].restriction_status !== null &&
            response.data.data.internet[0].restriction_status !== ""
          ) {
            restrictionStatus =
              response.data.data.internet[0].restriction_status;
          }
          accountInfo.restrictionStatus = restrictionStatus;
          store.dispatch(updateAccountInfoState(accountInfo));

          const userName = response.data.data.internet[0].username;
          store.dispatch(updateUsername(userName));
          getSlamid(cartridgeNumber, userName, isFromAccountSearch);
          getModemAuth(cartridgeNumber, userName);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const siebelFlow = (
  accountID: string,
  isFromAccountSearch: boolean
): void => {
  // siebel flow step1: get TPII cartridge number
  if (accountID) {
    let newAccountID = accountID;

    const performAddressCheckerCall = () => {
      if (newAccountID.length < 9) {
        // Add leading zeros to accountID
        newAccountID = accountID.padStart(newAccountID.length + 1, "0");
      }
      const { login, token } = store.getState().login;
      const apiPath = login.useTPIIProxy
        ? `${login.oneAssistApi}/tpii/customer?search_type=brm_id&search_value=${newAccountID}&info_flag=1`
        : `${TPIIApis.getTPIICartridgeNumber}${newAccountID}&info_flag=1`;

      const config = login.useTPIIProxy
        ? { headers: { Authorization: token.idToken } }
        : {};

      store.dispatch(
        showProgressBar({
          isShown: true,
          pbMessage: "Account details loading...",
        })
      );
      axios
        .get(apiPath, config)
        .then((response) => {
          if (response.data.status === "Ok") {
            const cartridgeArray: string[] = [];
            // get all cartridge numbers
            response.data.data.customer.forEach((ele: any) => {
              if (ele.status === "Active") {
                cartridgeArray.push(ele.id);
              }
            });
            store.dispatch(updateCartridgeGroup(cartridgeArray));
            store.dispatch(updateCartridge(cartridgeArray[0]));

            getTPIIUserName(cartridgeArray[0], isFromAccountSearch);
          } else if (
            (response.data.status === "Error" ||
              response.data.status === "Validation") &&
            newAccountID.length < 9
          ) {
            performAddressCheckerCall();
          }
        })
        .catch((error) => {
          store.dispatch(
            showProgressBar({
              isShown: false,
              pbMessage: "",
            })
          );
          if (newAccountID.length < 9) {
            performAddressCheckerCall();
          }
          console.log(error);
        });
    };

    performAddressCheckerCall();
  }
};

// eslint-disable-next-line
export const getTPIIBillingAddress = (response: any): void => {
  const customerValues = { ...store.getState().customer.customerInfo };
  customerValues.CRMServiceAddress = response.data.postal_address;
  store.dispatch(updateCustomerInfoState(customerValues));
};

export const displayAccountInfo = (cartridgeNumber: string): void => {
  const accountInfo = { ...store.getState().customer.accountInfo };
  const customerInfo = { ...store.getState().customer.customerInfo };
  const { login, token } = store.getState().login;
  const apiPath = login.useTPIIProxy
    ? `${login.oneAssistApi}/tpii/customer/${cartridgeNumber}`
    : `${TPIIApis.getTPIICustomerData}${cartridgeNumber}`;

  const config = login.useTPIIProxy
    ? { headers: { Authorization: token.idToken } }
    : {};
  axios
    .get(apiPath, config)
    .then((response) => {
      if (response.data.status === "Ok") {
        accountInfo.balance = `-${response.data.data.overdue_balance}`;
        // Get TPII Billing Address
        getTPIIBillingAddress(response.data);
      }
      store.dispatch(updateAccountInfoState(accountInfo));
      store.dispatch(updateCustomerInfoState(customerInfo));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const TPIIFlow = (
  accountID: string,
  isFromAccountSearch: boolean
): void => {
  if (accountID) {
    store.dispatch(
      showProgressBar({
        isShown: true,
        pbMessage: "Account details loading...",
      })
    );

    const cartridgeNumber = accountID;
    store.dispatch(updateCartridgeGroup([cartridgeNumber]));
    store.dispatch(updateCartridge(cartridgeNumber));
    getTPIIUserName(cartridgeNumber, isFromAccountSearch);
    displayAccountInfo(cartridgeNumber); // This only for TPII
  }
};

// eslint-disable-next-line
export const getMedicalDependency = (response: any): any => {
  // Vulnerable
  if (response.SERVICE_VULNERABLE_CUSTOMER_FLAG === "Y") {
    store.dispatch(updateVulnerable(true));
  } else {
    store.dispatch(updateVulnerable(false));
  }

  const flags = [
    response.VULNERABLE_BILLING_ACCOUNT_FLAG,
    response.SERVICE_VULNERABLE_CUSTOMER_FLAG,
    response.SERVICE_MEDICAL_SECURITY_ALARM_FLAG,
  ];

  if (flags.every((flag) => flag === "" || flag === null || flag === "N")) {
    return { status: "greenLight", label: "No" };
  }
  if (flags.some((flag) => flag === "Y")) {
    return { status: "redLight", label: "Yes, Check CRM" };
  }
  return { status: "amberLight", label: "Unknown, Check CRM" };
};

export const getMigration = (flag: string | null): string => {
  if (flag === "N" || flag === "" || flag === null) {
    return "greenLight";
  }
  if (flag === "Y") {
    return "redLight";
  }
  return "amberLight";
};

export const formatDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

export const parseDate = (dateString: string): Date | null => {
  if (dateString.includes("/")) {
    // Format: "18/06/2020"
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }
  if (dateString.includes("T")) {
    // Format: "2023-07-01T00:00:00Z"
    const dateParts = dateString.split("T")[0].split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);
    return new Date(year, month, day);
  }
  // Format: "2023-07-01 00:00:00"
  return new Date(dateString.replace(/-/g, "/"));
};

// eslint-disable-next-line
export const getTerm = (termDate: string | null): any => {
  if (termDate === "" || termDate === null || termDate === "No") {
    return { status: "amberLight", label: "Unknown - Check CRM" };
  }

  const date = parseDate(termDate);

  if (date === null || Number.isNaN(date.getTime())) {
    return { status: "amberLight", label: "Unknown - Check CRM" };
  }

  const today = new Date();
  const twoYearsAgo = new Date(
    today.getFullYear() - 2,
    today.getMonth(),
    today.getDate()
  );
  const oneYearAgo = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );

  if (date <= twoYearsAgo) {
    return { status: "redLight", label: formatDate(date) };
  }
  if (date >= oneYearAgo) {
    return { status: "greenLight", label: formatDate(date) };
  }

  if (date < oneYearAgo && date > twoYearsAgo) {
    return { status: "amberLight", label: `${formatDate(date)} Check Term` };
  }

  return { status: "greenLight", label: formatDate(date) };
};

// eslint-disable-next-line
export const getCustomerName = (response: any): string => {
  const firstName = response.CONTACT_FIRST_NAME;
  const lastName = response.CONTACT_LAST_NAME;

  if (firstName === null || lastName === null) {
    return response.CUST_NAME;
  }

  return `${firstName} ${lastName}`;
};

// eslint-disable-next-line
export const getAccountBalance = (response: any): string => {
  if (response.COLLECTION_STATUS_CODE === "N") {
    return response.CURRENT_BALANCE_BILL_AMOUNT &&
      response.CURRENT_BALANCE_BILL_AMOUNT.trim() !== ""
      ? response.CURRENT_BALANCE_BILL_AMOUNT
      : "Unknown";
  }
  if (response.COLLECTION_STATUS_CODE === "Y") {
    return response.COLLECTIONS_ENTRY_AMOUNT &&
      response.COLLECTIONS_ENTRY_AMOUNT.trim() !== ""
      ? `-${response.COLLECTIONS_ENTRY_AMOUNT}`
      : "Unknown";
  }
  return "Unknown";
};

export const displayCustomerInfo = (
  response: any, // eslint-disable-line
  isFromAccountSearch: boolean
): void => {
  const customerInfo = { ...store.getState().customer.customerInfo };

  customerInfo.Name = getCustomerName(response);
  customerInfo.AccountID = response.ACCT_NUM;
  customerInfo.Status = `${response.SERVICE_STATUS_NAME || ""} ${
    response.ACCT_TYPE_CD || ""
  } ${response.SEGMENT || ""}`;
  customerInfo.DOB = response.DOB || "Unknown";
  customerInfo.Email = response.ACCT_NOT_EMAIL ? response.ACCT_NOT_EMAIL : "";
  customerInfo.Mobile = displayMobileNumber(response);
  customerInfo.CRMServiceAddress =
    response.CUSTOMER_SERVICE_FULL_ADDRESS || "Unknown";
  customerInfo.AcctName = response.ACCT_NAME || "Unknown";

  const accountInfo = { ...store.getState().customer.accountInfo };

  accountInfo.pendingOrders = "Default";
  accountInfo.medicalDependency = getMedicalDependency(response);
  accountInfo.inMigration = getMigration(response.MIGRATION_FLAG);
  accountInfo.managed =
    response.ACCOUNT_MANAGER_NAME === null ||
    response.ACCOUNT_MANAGER_NAME === "" ||
    response.ACCOUNT_MANAGER_NAME === "No"
      ? "No"
      : response.ACCOUNT_MANAGER_NAME;
  accountInfo.DOM = response.BILL_DAY_OF_MONTH_NUMBER
    ? response.BILL_DAY_OF_MONTH_NUMBER
    : "Unknown";
  accountInfo.inTerm = getTerm(response.PLAN_CONTRACT_TERM_END_DATE);
  // account balance siebel
  if (response.SOURCE_SYSTEM === "SBL") {
    accountInfo.balance = getAccountBalance(response);
  }
  store.dispatch(updateAccountInfoState(accountInfo));

  if (response.SOURCE_SYSTEM === "SBL") {
    customerInfo.CRM = "Siebel";
    store.dispatch(updateSiebelStatus(true));
    // IF CRM=SBL & FIS=N => not support
    if (response.FIS_FLAG === "N") {
      store.dispatch(updateSupport(false));
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
    } else {
      store.dispatch(updateSupport(true));
      // go to Siebel flow
      siebelFlow(customerInfo.AccountID, isFromAccountSearch);
    }
  }

  if (response.SOURCE_SYSTEM === "TPII") {
    customerInfo.CRM = "TPII";
    store.dispatch(updateSiebelStatus(false));
    // go to TPII flow
    TPIIFlow(customerInfo.AccountID, isFromAccountSearch);
  }

  store.dispatch(updateCustomerInfoState(customerInfo));
};

// eslint-disable-next-line
export const getLastContact = (response: any): string => {
  let lastContact = "";
  if (response.LAST_1_DAY === "Y") {
    lastContact = "Day";
  } else if (response.LAST_7_DAY === "Y") {
    lastContact = "Week";
  } else if (response.LAST_21_DAY === "Y") {
    lastContact = "3 Weeks";
  } else if (response.LAST_30_DAY === "Y") {
    lastContact = "Month";
  } else if (response.LAST_31_90_DAY === "Y") {
    lastContact = "3 Months";
  } else if (response.LAST_90_PLUS_DAY === "Y") {
    lastContact = "Over 3 Mths";
  } else {
    lastContact = "Unknown";
  }
  return lastContact;
};

interface ConnectTypeCheckList {
  [key: string]: string;
  HWB_FLAG: string;
  HPW_FLAG: string;
  ADSL_FLAG: string;
  VDSL_FLAG: string;
  FIBRE_FLAG: string;
  HFC_FLAG: string;
}

// eslint-disable-next-line
export const getConnectType = (response: any): string => {
  // IF FIBRE_FLAG" = N => not support
  if (response.FIBRE_FLAG === "N") {
    store.dispatch(updateSupport(false));
    store.dispatch(
      showProgressBar({
        isShown: false,
        pbMessage: "",
      })
    );
  }

  const flags: { [key: string]: string } = {
    HWB_FLAG: response.HWB_FLAG,
    HPW_FLAG: response.HPW_FLAG,
    ADSL_FLAG: response.ADSL_FLAG,
    VDSL_FLAG: response.VDSL_FLAG,
    FIBRE_FLAG: response.FIBRE_FLAG,
    HFC_FLAG: response.HFC_FLAG,
  };
  const connectTypeCheckList: ConnectTypeCheckList = {
    HWB_FLAG: "Wireless Broadband",
    HPW_FLAG: "Home phone wireless",
    ADSL_FLAG: "ADSL",
    VDSL_FLAG: "VDSL",
    FIBRE_FLAG: "Fibre",
    HFC_FLAG: "HFC (Hybrid Fibre Coaxial)",
  };
  const count = Object.values(flags).filter((val) => val === "Y").length;
  // Checkall flags, if over 1 flag is Y, go TPII
  if (count >= 2) {
    return "TBD";
  }
  if (count === 1) {
    const flagKey = Object.keys(flags).find((key) => flags[key] === "Y");
    return connectTypeCheckList[flagKey || ""];
  }
  return "Unknown";
};

// eslint-disable-next-line
export const displayServiceInfo = (response: any): void => {
  const currentServiceInfo = store.getState().service.service;
  const { isVoIP } = store.getState().global;
  const uploadSpeed = parseInt(response.BROADBAND_UPLOAD_SPEED_MB, 10);
  const uploadSpeedString = Number.isNaN(uploadSpeed)
    ? "Unknown"
    : `${uploadSpeed.toString()}Mbps avg`;
  const downloadSpeed = parseInt(response.BROADBAND_DOWNLOAD_SPEED_MB, 10);
  const downloadSpeedString = Number.isNaN(downloadSpeed)
    ? "Unknown"
    : `${downloadSpeed.toString()}Mbps avg`;
  const serviceInfo = {
    ...currentServiceInfo,
    lastContact: getLastContact(response),
    FIS: renameYN(response.FIS_FLAG),
    VoIP: isVoIP ? "Yes" : renameYN(response.VOIP_FLAG),
    multiVoIP: renameYN(response.MULTI_VOIP_FLAG),
    accType: response.ACCT_TYPE_CD,
    connectType: getConnectType(response),
    planName: response.PROD_NAME || "Unknown",
    planSpeed: {
      upload: uploadSpeedString,
      download: downloadSpeedString,
    },
    hardware: {
      hardware: response.NETWORK_CPE_TYPE || "Unknown",
      serialNumber: response.NETWORK_CPE_SERIAL_NUMBER || "Unknown",
    },
    decoAccountFlag: response.DECO_ACCOUNT_FLAG,
  };
  store.dispatch(updateServiceState(serviceInfo));
};

export const displayUpdateTime = (): void => {
  const currentDate = new Date();
  const previousDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
  const formatTime = new Date(
    previousDate.toString().replace(/\..+/, "")
  ).toLocaleString();
  store.dispatch(getUpdateTime(formatTime));
};
