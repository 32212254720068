import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  getModemAuth,
  removeZeros,
  getLoginHistory,
  getInternetUsage,
  getOutages,
  removeLFC,
} from "../../../helpers/ToolsetHelper";
import { checkLineTest, getONTOverTime } from "../../../helpers/LineTestHelper";
import { otherLFCLineTest } from "../../../helpers/OtherLFCHelper";
import Tooltips from "../Tooltips/Tooltips";
import {
  updateLanTestNotice,
  updateModemAuthNotice,
  updateInternetUsageStatus,
  updateUnplannedOutage,
} from "../../../redux/toolsetSlice";

type Props = {
  expendIndex: number;
  onSelectMenu: (selectIndex: number) => void;
};

const FixedToolset: React.FC<Props> = ({
  expendIndex,
  onSelectMenu,
}: Props) => {
  const {
    asid,
    lan,
    lineTest,
    cartridge,
    username,
    modemAuth,
    service,
  } = useAppSelector((state) => state.service);
  const [isLanMatch, setIsLanMatch] = useState(false);
  const [isShowPlannedOutageNotice, setIsShowPlannedOutageNotice] = useState(
    false
  );
  const [
    isShowUnplannedOutageNotice,
    setIsShowUnplannedOutageNotice,
  ] = useState(false);
  const [isShowFutureOutageNotice, setIsShowFutureOutageNotice] = useState(
    false
  );
  const {
    unplannedOutage,
    plannedOutage,
    inEffectOutage,
    futureOutage,
    modemAuthNotice,
    internetUsage,
    isLanTested,
    lanTestNotice,
    loginHistory,
    ONTStatus,
    rgwBandWidth,
    ONTOverTime,
  } = useAppSelector((state) => state.toolset);
  const serviceValue = useAppSelector((state) => state.service);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLanTested) {
      if (
        lineTest.handover === lan.handover &&
        lineTest.CV === removeZeros(lan.CV) &&
        lineTest.SV === removeZeros(lan.SV)
      ) {
        setIsLanMatch(true);
        dispatch(updateLanTestNotice("Match"));
      } else {
        setIsLanMatch(false);
        dispatch(updateLanTestNotice("Not a Match"));
      }
    } else {
      setIsLanMatch(false);
    }
  }, [
    isLanTested,
    dispatch,
    lineTest.CV,
    lineTest.SV,
    lineTest.handover,
    lan.CV,
    lan.SV,
    lan.handover,
  ]);

  useEffect(() => {
    // PlannedOutageNotice: if any other notice exist, don't show "No outage reported"
    if (
      (unplannedOutage.outageTitle ||
        inEffectOutage.outageTitle ||
        futureOutage.outageTitle) &&
      plannedOutage.outageTitle === "No Outage Reported"
    ) {
      setIsShowPlannedOutageNotice(false);
    } else if (plannedOutage.outageTitle) {
      setIsShowPlannedOutageNotice(true);
    }
    // UnplannedOutageNotice: if any other notice exist, don't show "No outage reported"
    if (
      (plannedOutage.outageTitle ||
        inEffectOutage.outageTitle ||
        futureOutage.outageTitle) &&
      unplannedOutage.outageTitle === "No Outage Reported"
    ) {
      setIsShowUnplannedOutageNotice(false);
    } else if (unplannedOutage.outageTitle) {
      setIsShowUnplannedOutageNotice(true);
    }
    // UnplannedOutageNotice: if any other notice exist, don't show "No outage reported"
    if (
      (plannedOutage.outageTitle ||
        inEffectOutage.outageTitle ||
        unplannedOutage.outageTitle) &&
      futureOutage.outageTitle === "No Outage Reported"
    ) {
      setIsShowFutureOutageNotice(false);
    } else if (futureOutage.outageTitle) {
      setIsShowFutureOutageNotice(true);
    }
  }, [
    unplannedOutage.outageTitle,
    inEffectOutage.outageTitle,
    plannedOutage.outageTitle,
    futureOutage.outageTitle,
  ]);

  return (
    <div className="toolset">
      <button
        className={`toolsetTitle ${expendIndex === 2 ? "selected" : ""}`}
        type="button"
        onClick={() => onSelectMenu(2)}
      >
        Fixed Tech toolset
      </button>
      <p className="LFC">
        <i className="serviceName">LFC</i>
        <i className="colon">:</i>
        <i className="serviceValue">{removeLFC(serviceValue.service.LFC)}</i>
      </p>
      <div className={`subToolset ${expendIndex === 2 ? "active" : ""}`}>
        <div className="subToolsetWrapper">
          <div
            className="toolsetSubTitle"
            onClick={() => {
              if (!asid) {
                dispatch(
                  updateUnplannedOutage({
                    outageLight: "red",
                    outageTitle: "No asid found",
                    outageNotice: "",
                  })
                );
              } else if (serviceValue.service.LFC === "LFC Chorus") {
                getOutages(asid, "Chorus");
              } else if (serviceValue.service.LFC === "LFC UFF") {
                getOutages(asid, "TFF");
              } else {
                dispatch(
                  updateUnplannedOutage({
                    outageLight: "grey",
                    outageTitle: "Data not available for this provider",
                    outageNotice: "",
                  })
                );
              }
            }}
            aria-hidden
          >
            Outages
          </div>
          <div className="toolsetContentWrapper">
            <Tooltips type="Outages" width="normal" isAdmin />
            {isShowUnplannedOutageNotice && (
              <div>
                <p
                  className={`${
                    unplannedOutage.outageTitle ? "active" : "hidden"
                  } ${unplannedOutage.outageLight}`}
                />
                <span className="toolsetNotice">
                  {unplannedOutage.outageTitle}
                </span>
              </div>
            )}
            {isShowPlannedOutageNotice && (
              <div>
                <p
                  className={`${
                    plannedOutage.outageTitle ? "active" : "hidden"
                  } ${plannedOutage.outageLight}`}
                />
                <span className="toolsetNotice">
                  {plannedOutage.outageTitle}
                </span>
              </div>
            )}
            <div>
              <p
                className={`${
                  inEffectOutage.outageTitle ? "active" : "hidden"
                } notMatchCube`}
              />
              <span className="toolsetNotice">
                {inEffectOutage.outageTitle}
              </span>
            </div>
            {isShowFutureOutageNotice && (
              <div>
                <p
                  className={`${
                    futureOutage.outageTitle ? "active" : "hidden"
                  } ${futureOutage.outageLight}`}
                />
                <span className="toolsetNotice">
                  {futureOutage.outageTitle}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="subToolsetWrapper">
          <div
            className="toolsetSubTitle"
            onClick={() => {
              if (!cartridge || !username) {
                dispatch(
                  updateModemAuthNotice("No cartidge or username found")
                );
              } else {
                getModemAuth(cartridge, username);
              }
            }}
            aria-hidden
          >
            Is modem Authenticated
          </div>
          <div className="toolsetContentWrapper">
            <Tooltips type="ModemAuth" width="normal" isAdmin />
            <p
              className={`${modemAuthNotice ? "active" : "hidden"} ${
                modemAuth.isAuth ? "matchCube" : "notMatchCube"
              }`}
            />
            <span className="toolsetNotice">{modemAuthNotice}</span>
          </div>
        </div>
        <div className="subToolsetWrapper">
          <div
            className="toolsetSubTitle"
            onClick={() => {
              if (serviceValue.service.LFC === "LFC Chorus") {
                checkLineTest(false, asid, "lan");
              } else {
                otherLFCLineTest(false, asid, "lan");
              }
            }}
            aria-hidden
          >
            Check SV/CV LAN | Handover link
          </div>
          <div className="toolsetContentWrapper">
            <Tooltips type="LAN" width="normal" isAdmin />
            <p
              className={`${lanTestNotice ? "active" : "hidden"} ${
                isLanMatch ? "matchCube" : "notMatchCube"
              }`}
            />
            <span className="toolsetNotice">{lanTestNotice}</span>
          </div>
        </div>
        <div className="subToolsetWrapper">
          <div
            className="toolsetSubTitle"
            onClick={() => {
              getLoginHistory();
            }}
            aria-hidden
          >
            Login History
          </div>
          <div className="toolsetContentWrapper">
            <Tooltips type="LoginHistory" width="normal" isAdmin />
            <p
              className={`${loginHistory.loginNotice ? "active" : "hidden"} ${
                loginHistory.isGetLoginHistory ? "matchCube" : "notMatchCube"
              }`}
            />
            <span className="toolsetNotice">{loginHistory.loginNotice}</span>
          </div>
        </div>
        <div className="subToolsetWrapper">
          <div
            className="toolsetSubTitle"
            onClick={() => {
              if (serviceValue.service.LFC === "LFC Chorus") {
                checkLineTest(false, asid, "ONT");
              } else if (
                serviceValue.service.LFC === "LFC Enable" ||
                serviceValue.service.LFC === "LFC UFF" ||
                serviceValue.service.LFC.includes("Northpower")
              ) {
                otherLFCLineTest(false, asid, "ONT");
              }
            }}
            aria-hidden
          >
            Is ONT on - Line test
          </div>
          <div className="toolsetContentWrapper">
            <Tooltips type="ONT" width="normal" isAdmin />
            <p
              className={`${ONTStatus.ONTStatusNotice ? "active" : "hidden"} ${
                ONTStatus.ONTStatusLight
              }`}
            />
            <span className="toolsetNotice">{ONTStatus.ONTStatusNotice}</span>
            {rgwBandWidth.rgwBandWidthNotice && (
              <p className="rgwBandWidth">
                <i> --- RGW Status</i>
                <i
                  className={`${
                    rgwBandWidth.rgwBandWidthNotice ? "active" : "hidden"
                  } ${rgwBandWidth.rgwBandWidthLight}`}
                />
                {rgwBandWidth.rgwBandWidthNotice}
              </p>
            )}
            {rgwBandWidth.rgwBandWidthDownstream !== "" && (
              <div className="rgwBandWidthStream">
                <p>
                  Traffic during test: Downstream{" "}
                  {rgwBandWidth.rgwBandWidthDownstream} Upstream{" "}
                  {rgwBandWidth.rgwBandWidthUpstream}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="subToolsetWrapper">
          <div
            className="toolsetSubTitle"
            onClick={() => {
              if (!cartridge || !username) {
                dispatch(
                  updateInternetUsageStatus({
                    isGetInternetUsage: false,
                    notice: "No cartidge or username found",
                  })
                );
              } else {
                getInternetUsage(cartridge, username);
              }
            }}
            aria-hidden
          >
            Internet Usage
          </div>
          <div className="toolsetContentWrapper">
            <Tooltips type="InternetUsage" width="normal" isAdmin />
            <p
              className={`${internetUsage.notice ? "active" : "hidden"} ${
                internetUsage.isGetInternetUsage ? "matchCube" : "notMatchCube"
              }`}
            />
            <span className="toolsetNotice">{internetUsage.notice}</span>
          </div>
        </div>
        <div className="subToolsetWrapper">
          <div
            className="toolsetSubTitle"
            onClick={() => {
              if (serviceValue.service.LFC === "LFC Chorus") {
                getONTOverTime(asid);
              }
            }}
            aria-hidden
          >
            ONT Status Over Time
          </div>
          <div className="toolsetContentWrapper">
            <Tooltips type="ONTOverTime" width="wide" isAdmin />
            <p
              className={`${
                service.LFC === "LFC Enable" ||
                service.LFC === "LFC UFF" ||
                service.LFC.includes("Northpower")
                  ? "greyPill"
                  : ""
              } ${ONTOverTime.notice ? "active" : "hidden"} ${
                ONTOverTime.notice === "No errors detected" &&
                ONTOverTime.isGetONTOverTime
                  ? "matchCube"
                  : "notMatchCube"
              }`}
            />
            <span className="toolsetNotice">{ONTOverTime.notice}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedToolset;
