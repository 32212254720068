import React from "react";
import { useAppSelector } from "../../redux/hooks";

const AccountInfo: React.FC = () => {
  const accountInfoState = useAppSelector((state) => state.customer);

  const getMigrationValue = (migrationLight: string): string => {
    if (migrationLight === "greenLight") return "No";
    if (migrationLight === "redLight") return "Yes";
    if (migrationLight === "") return "";
    return "Unknown";
  };

  const getBalanceLight = (balance: string): string => {
    if (balance === "Unknown") {
      return "amberLight";
    }
    const parsedBalance = parseFloat(balance);

    if (!Number.isNaN(parsedBalance)) {
      return parsedBalance < 0 ? "redLight" : "greenLight";
    }

    return "amberLight";
  };

  const removeNegativeSign = (balance: string): string => {
    return balance.startsWith("-") ? balance.slice(1) : balance;
  };

  return (
    <div className="accountInfo">
      <div className="column1">
        <p className="infoWrapper">
          <i className="infoTitle">Account balance</i>
          <i className="colon">:</i>
          <i
            className={`${
              accountInfoState.accountInfo.balance ? "light" : ""
            } ${getBalanceLight(accountInfoState.accountInfo.balance)}`}
          />
          <i className="infoValue">
            {removeNegativeSign(accountInfoState.accountInfo.balance)}
          </i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">Restriction Status</i>
          <i className="colon">:</i>
          <i
            className={`${
              accountInfoState.accountInfo.restrictionStatus ? "light" : ""
            } ${
              accountInfoState.accountInfo.restrictionStatus === "No"
                ? "greenLight"
                : "redLight"
            }`}
          />
          <i className="infoValue">
            {accountInfoState.accountInfo.restrictionStatus}
          </i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">DOM</i>
          <i className="colon">:</i>
          <i className="infoValue">{accountInfoState.accountInfo.DOM}</i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">In term</i>
          <i className="colon">:</i>
          <i
            className={`${
              accountInfoState.accountInfo.inTerm.status ? "light" : ""
            } ${accountInfoState.accountInfo.inTerm.status}`}
          />
          <i className="infoValue">
            {accountInfoState.accountInfo.inTerm.label}
          </i>
        </p>
      </div>
      <div className="column2">
        <p className="infoWrapper">
          <i className="infoTitle">Vulnerable or Medical dependency</i>
          <i className="colon">:</i>
          <i
            className={`${
              accountInfoState.accountInfo.medicalDependency.status
                ? "light"
                : ""
            } ${accountInfoState.accountInfo.medicalDependency.status}`}
          />
          <i className="infoValue">
            {accountInfoState.accountInfo.medicalDependency.label}
          </i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">Pending orders</i>
          <i className="colon">:</i>
          <i
            className={`${
              accountInfoState.accountInfo.pendingOrders ? "light" : ""
            } amberLight`}
          />
          <i className="infoValue">
            {accountInfoState.accountInfo.pendingOrders
              ? "Unknown - Check CRM"
              : ""}
          </i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">Account in migration</i>
          <i className="colon">:</i>
          <i
            className={`${
              accountInfoState.accountInfo.inMigration ? "light" : ""
            } ${accountInfoState.accountInfo.inMigration}`}
          />
          <i className="infoValue">
            {getMigrationValue(accountInfoState.accountInfo.inMigration)}
          </i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">Managed Account</i>
          <i className="colon">:</i>
          <i
            className={`${
              accountInfoState.accountInfo.managed ? "light" : ""
            } ${
              accountInfoState.accountInfo.managed === "No"
                ? "greenLight"
                : "redLight"
            }`}
          />
          <i className="infoValue">{accountInfoState.accountInfo.managed}</i>
        </p>
      </div>
    </div>
  );
};

export default AccountInfo;
